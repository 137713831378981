var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.type === "Info"
        ? _c("class-info", {
            attrs: { classInfo: _vm.classInfo, pageFrom: _vm.pageFrom },
            on: { updateView: _vm.updateView },
          })
        : _vm.type === "Student"
        ? _c("class-student", {
            attrs: {
              classInfo: _vm.classInfo,
              pageFrom: _vm.pageFrom,
              classStudent: _vm.classStudent,
            },
          })
        : _vm.type === "Lesson"
        ? _c("class-lesson", {
            attrs: { classInfo: _vm.classInfo, pageFrom: _vm.pageFrom },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div>
    <el-card shadow="never">
      <div slot="header" class="flex" style="align-items: center;">
        <span>排课数据</span>
        <div class="flex_1"></div>
        <text-button
          icon="el-icon-refresh"
          contentTit="刷新"
          @click.native="search"
        ></text-button>
        <text-button
          icon="el-icon-setting"
          contentTit="列设置"
          :colSetting="colSetting"
          @update="update"
          :ispopover="true"
          :baseColSetting="baseColSetting"
        ></text-button>
      </div>

      <el-table-bar fixed :static="true">
        <el-table
          :key="tableKey"
          :data="dataList"
          fit
          :highlight-current-row="true"
          @sort-change="tableSortAll"
          :default-sort="{ prop: 'start_time', order: 'ascending' }"
        >
        <!-- style="margin-top: 15px;" -->
          <el-table-column
            v-for="item of defaultHeader"
            :key="item.label"
            :label="item.label"
            :align="item.align ? item.align : 'left'"
            :min-width="item.width ? item.width : 'auto'"
            :sortable="item.sortable ? item.sortable : false"
            :prop="item.prop"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <template v-if="item.label === '上课时间'">
                <span>
                  <span>（{{scope.row.start_time.substring(0, 10) | weekDay}}）</span>
                  {{ scope.row.start_time | formatDateStart }} {{scope.row.end_time | formatDateEnd }}</span>
              </template>
              <template v-else-if="item.label === '课堂任务'">
                <el-row v-if="scope.row.courseUnitlist.length != 0" :key="index" v-for="(item,index) in scope.row.courseUnitlist">
                  <span  @click="manageLessTask(scope.row)">{{item.title}}</span>
                </el-row>
                <el-row v-if="scope.row.courseUnitlist.length == 0">
                  <span>无</span>
                </el-row>
              </template>
              <template v-else-if="item.label === '上课学员'">
                <el-popover
                  v-if="scope.row.lessonStudentList !== null"
                  placement="right"
                  width="350"
                  style="height: 400px;overflow-y: scroll"
                  trigger="click">
                  <el-table :data="scope.row.lessonStudentList">
                    <el-table-column property="name" label="姓名" align="center"></el-table-column>
                    <el-table-column width="160"  property="loginName" label="登录名" align="center"></el-table-column>
                    <el-table-column label="出勤状态" align="center">
                      <template slot-scope="scope">
                        <el-tag v-if="scope.row.isAttend === '是'" type="success">已出勤</el-tag>
                        <el-tag v-else type="danger">未出勤</el-tag>
                      </template>
                    </el-table-column>
                  </el-table>
                  <el-button slot="reference" type="text" style="text-decoration:underline">{{scope.row.lessonStudentList.length}}</el-button>
                </el-popover>
                <span v-else>0</span>
              </template>
              <template v-else-if="item.label === '教师名称'">
                <span>
                  {{scope.row.zjName}}
                </span>
              </template>
              <template v-else-if="item.label === '操作'">
                <el-button v-if="scope.row.courseUnitlist && scope.row.courseUnitlist[0] && scope.row.courseUnitlist[0].teachOnOff=='Y'" type="text" @click="gotoGiveLesson(scope.row)">
                  去上课
                </el-button>
              </template>
              <span v-else>{{ scope.row[item.prop] }}</span>
            </template>
          </el-table-column>
        </el-table>
      </el-table-bar>

      <el-pagination
        class="fr-pagination"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pagination.currentPage"
        :page-sizes="pagination.pageSizes"
        :page-size="pagination.pageSize"
        :layout="pagination.layout"
        :total="pagination.total"
      ></el-pagination>
    </el-card>
  </div>
</template>

<script>
import TextButton from '@/components/TextButton'
import SearchQuery from '@/components/SearchQuery'
import getStateColor from '@/mixins/getStateColor'
import { getLessonList } from "@/apiteacher/teacher";

import { getLessonInfoByClassId ,getCourseInfo} from '@/apiteacher/teacher'

// 默认展示列
const baseColSetting = [
  {
    label: '上课时间',
    prop: 'start_time',
    state: true,
    sortable: true,
    align: 'center',
    width: '255',
  },
  {
    label: '课堂任务',
    prop: 'courseUnitlist',
    state: true,
    align: 'center',
    width: '150',
  },
  {
    label: '上课学员',
    state: true,
    align: 'center',
    width: '150',
  },
  {
    label: '教师名称',
    state: true,
    align: 'center',
    width: '150',
  },
  {
    label: '排课状态',
    prop: 'finished_status',
    state: true,
    align: 'center',
    width: '100',
  },
  {
    label: '操作',
    prop: 'make',
    state: true,
    align: 'center',
    width: '100',
  },
]
export default {
  props: {
    classInfo: {
      default: () => {
        return {}
      },
      type: Object
    },pageFrom:{
      default: '',
      type: String
    },
  },
  mixins: [getStateColor],
  components: {TextButton, SearchQuery},
  data() {
    return {
      colSetting: JSON.parse(JSON.stringify(baseColSetting)), // 表格展示列
      baseColSetting: JSON.parse(JSON.stringify(baseColSetting)),
      pagination: {
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 20, 30, 40],
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
      },
      dataList: [],
      tableKey: 1,
      isDesc: false, // 列表默认为正序
      product_id: this.$route.query.productId, // 收费课程id
      lessonDialog: false,
      courseUnitIdList: [],
      lesson_id: '',
      classId: '',
      addlessonData: null,
      batchDialog: false,
      DeedBackDialog: false,
      attendanceDialog: false,
      schooltime: '', // 上课时间
      dialogJionLive: false,
      liveRoomId: '',
      dialogCode: false,
      dialogLive: false,
      lessonLiveName: '',
      liveData: null,
      lessonLiveData: null,
    }
  },
  created () {
    this.getLessonInfoByClassId()
  },
  computed: {
    defaultHeader() {
      return this.colSetting.filter(item => item.state)
    },
  },
  methods: {
    // 去上课
    async gotoGiveLesson (row) {
      const res = await getCourseInfo({
        id:row.courseUnitlist[0].courseId,
      })

      let query = {
          courseUnitId: row.courseUnitlist[0].id,
          courseTitle: row.courseUnitlist[0].title,
          lessonId: row.id,
          classId: this.$route.query.classID,
          courseStatus: row.finished_status,
          courseType:res.body.type,
          ci: row.courseUnitlist[0].courseId,
          cn: row.courseName,
          ui: row.courseUnitlist[0].id,
          un: row.courseUnitlist[0].title,//课节名称
        }
      let routeData = this.$router.resolve({
        path: '/teacher/giveLesson-detail',
        query: {...query}
      })
      window.open(routeData.href, '_blank')
    },
    async getLessonInfoByClassId () { // 获取班级排课列表
      const res = await getLessonInfoByClassId({
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
        class_id: this.$route.query.classID,
        isDesc: this.isDesc
      })
      const dataList = res.body.list

      this.dataList = dataList
      this.pagination.total = res.body.total
    },

    jumpLive (row) {
      this.liveRoomId = row.liveId
      this.dialogJionLive = true
    },

    update(data) {
      this.tableKey = this.tableKey + 1
      if (data.lenght !== 0) {
        this.colSetting.forEach(item => {
          item.state = false
          data.forEach(ele => {
            if (ele === item.label && !item.state) {
              item.state = true
            }
          })
        })
      } else {
        this.colSetting = []
      }
    },

    // 管理课堂任务
    manageLessTask (row) {
      if (row.finished_status === '已上课') {
        window.$msg('该排课已上课', 2)
        return false
      }
      if (row.courseUnitlist.length !== 0) {
        const courseUnitIdList = []
        row.courseUnitlist.forEach((ele, idx) => {
          courseUnitIdList.push(ele.id)
        })
        this.courseUnitIdList = courseUnitIdList
      }
      this.lesson_id = row.id
      this.classId = this.$route.query.classID
      this.lessonDialog = true
    },

    search() {
      this.pagination.currentPage = 1
      this.getLessonInfoByClassId()
    },

    // 时间排序
    tableSortAll(column) {
      switch (column.order) {
        case 'ascending':
          this.isDesc = false
          break
        case 'descending':
          this.isDesc = true
      }
      this.getLessonInfoByClassId()
    },

    // 分页
    handleCurrentChange(val) {
      this.pagination.currentPage = val
      this.getLessonInfoByClassId()
    },
    handleSizeChange(val) {
      this.pagination.pageSize = val
      this.getLessonInfoByClassId()
    },

    getStateColor (state) {
      switch (state) {
        case '已上课':
          return '#4DA54D'
        case '未上课':
          return '#f56c6c'
        default:
          return '#C8C6C6'
      }
    },
  },

}
</script>

<style lang="scss" scoped>
@import '../../style/container.scss';
</style>

<template>
  <div>
    <el-card style="margin-bottom:10px;">
      <div>
        班级名称：
        <span v-if="classInfo" v-text="classInfo.className"></span>
      </div>
      <div style="margin:10px 0;">
        任课老师：
        <span v-if="classInfo" v-text="classInfo.zjName"></span>
      </div>
      <div style="margin:10px 0;">
        班级类型：
        <span >
          <span>{{this.classInfo.type}}</span>
        </span>
      </div>
      <div style="margin:10px 0;">
        班容类型：
        <span >
          <span>{{this.classInfo.lctype}}</span>
        </span>
      </div>
      <div style="margin:10px 0;">
        授课课程：
        <span v-if="classInfo && this.classInfo.course != null">
          <span>{{this.classInfo.course.title}}</span>
        </span>
      </div>
    </el-card>
  </div>
</template>
<script>

export default {
  props: {
    classInfo: {
      default: () => {
        return {}
      },
      type: Object
    },pageFrom:{
      default: '',
      type: String
    },
  },

  data () {
    return {
      dialogClass: false,
      ordinaryClass: false,
      updateCourse:false,
      lessTeacher:[],
      lessAssistant:[],
      userTeacherDialog: false,
      class_id: this.$route.query.classID, // 班级id
      product_id: this.$route.query.productId // 收费课程id
    }
  },
  methods: {
    // 编辑班级
    editClass () {
      this.ordinaryClass = true
      this.dialogClass = true
    },

    updateDate() {
      this.$emit('updateView')
    },

// dateKey 时间处理
    datefmt (date) {
      let res = ''
      let year = date.getFullYear()
      let month = date.getMonth() + 1
      if (month < 10) {
        month = '0' + month
      }
      let day = date.getDate()
      if (day < 10) {
        day = '0' + day
      }
      res = `${year}-${month}-${day}`
      return res
    },

    getTeacherMessage () {
      this.$emit('updateView')
    }
  }
}
</script>

<style lang="scss" scoped>

</style>

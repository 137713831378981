<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    @open="openDialog"
    :close-on-click-modal="false"
    top="7vh"
    append-to-body
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span>修改学员密码</span>
      <span style="margin-left: 30px;color:#f7cf47;font-size:16px">*密码必须包含大写字母、小写字母、数字、特殊字符中的三种</span>
    </div>
    <el-form style="padding:30px 50px;padding-bottom: 0;">
      <el-form-item label="学员姓名:" :label-width="formLabelWidth">
        <p style="margin:0 0 0 5px;text-align: left;">{{resetUse.userName}}</p>
      </el-form-item>
      <el-form-item label="学号:" :label-width="formLabelWidth">
        <p style="margin:0 0 0 5px;text-align: left;">{{resetUse.loginName}}</p>
      </el-form-item>
      <el-form-item label="新密码：" :label-width="formLabelWidth">
        <el-input
          v-model="resetPwd1"
          autocomplete="off"
          placeholder="请输入新密码"
          style="width:25vw;"
          show-password
        ></el-input>
      </el-form-item>
      <el-form-item label="确认密码：" :label-width="formLabelWidth">
        <el-input
          v-model="resetPwd2"
          autocomplete="off"
          placeholder="请再次输入新密码"
          style="width:25vw;"
          show-password
        ></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="resetPwdSave">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { changeStuPass } from '@/api/merchantClass'
import { merchantPasswordCheck } from "@/api/user";

export default {
  name: "changePassword",
  props: {
    show: {
      default: false,
      type: Boolean
    },
    resetUse: {
      default: () => {
        return {};
      },
      type: Object
    }
  },
  data() {
    return {
      formLabelWidth: "100px",
      resetPwd1: "",
      resetPwd2: ""
    };
  },
  methods: {
    openDialog() {
      // console.log("rolename"+JSON.stringify(this.resetUse))
    },

    // 修改学员密码
    async changeStuPass(userid, newPassword) {
      await changeStuPass({
        id: userid,
        pwd: newPassword
      })
      $msg("密码修改成功")
      this.$emit("close");
    },
    close() {
      this.$emit("close");
    },
    // 提交修改
    async resetPwdSave() {
      if (this.resetPwd1 && this.resetPwd2 === "") {
        $msg("密码不能为空", 1);
        return false;
      }
      if (this.resetPwd1 && this.resetPwd1 === this.resetPwd2) {
        const formdate = new FormData();
        formdate.append('password', this.resetPwd2,)
        const res = await merchantPasswordCheck(formdate)
        if(!res.body){
          window.$msg('当前密码不符合密码规范，请重新填写密码(密码必须包含大写字母、小写字母、数字、特殊字符中的三种)', 2)
          return
        }
        this.changeStuPass(this.resetUse.user_id, this.resetPwd1);
      } else {
        $msg("两次密码输入不一致", 1);
        return false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../style/dialog";
.dialog-footer {
  text-align: left;
  margin-left: 50px;
  margin-bottom: 35px;
}

/deep/ .el-form-item {
  text-align: left;
  margin-bottom: 15px;
}
</style>

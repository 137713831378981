var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "table",
      on: {
        "&scroll": function ($event) {
          return _vm.getScroll($event)
        },
      },
    },
    [
      _c("span", { staticClass: "back-tit" }, [_vm._v("班级详情")]),
      _c("div", { staticClass: "row-bg" }, [
        _c("span", {
          staticStyle: { "margin-right": "25px" },
          domProps: { textContent: _vm._s(_vm.classInfo.className) },
        }),
      ]),
      _c(
        "el-tabs",
        {
          staticClass: "tb-tabs",
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        _vm._l(_vm.tabMapOptions, function (item) {
          return _c(
            "el-tab-pane",
            { key: item.key, attrs: { label: item.label, name: item.key } },
            [
              _vm.activeName == item.key
                ? _c("class-detail", {
                    attrs: {
                      type: item.key,
                      pageFrom: _vm.pageFrom,
                      classInfo: _vm.classInfo,
                      classStudent: _vm.classStudent,
                      scrollTopTable: _vm.scrollTopTable,
                    },
                    on: {
                      getClassTeacherInfoById: _vm.getClassTeacherInfoById,
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
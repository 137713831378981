import $axios from '@/utils/request'

// 获取班级老师信息
export function getClassTeacherInfoById(data) {
    const url = '/merchant/class/getClassTeacherInfoById'
    return $axios.fGet(url, data)
}
// 获取全部班级列表
export function getClassAll(data) {
    const url = '/merchant/class/getClassAll'
    return $axios.fPost(url, data)
}
// 删除班级
export function delClass(data) {
    const url = '/merchant/class/delClass'
    return $axios.fPost(url, data)
}
// 获取班级上课进度
export function getProgress(data) {
    const url = '/merchant/class/getProgress'
    return $axios.fGet(url, data)
}
// 新增班级
export function addClassData(data) {
    const url = '/merchant/class/addClass'
    return $axios.fPost(url, data)
}
// 新增班级
export function getDoubleClaByCourseId(data) {
    const url = '/merchant/class/getDoubleClaByCourseId'
    return $axios.fGet(url, data)
}
// 新增双师班级
export function copyDoubleCla(data) {
    const url = '/merchant/class/copyDoubleCla'
    return $axios.fPost(url, data)
}

// 获取商户课程列表
export function getCourseListByMerchant(data) {
    const url = '/merchant/course/getCourseListByMerchant'
    return $axios.fPost(url, data)
}
// 获取员工列表
export function listEmployee(data) {
    const url = '/merchant/user/listAllEmployee'
    return $axios.fGet(url, data)
}

// 替换班级教师
export function replaceTeacher(data) {
    const url = '/merchant/class/replaceTeacher'
    return $axios.fPost(url, data)
}

// 编辑班级
export function editClassData(data) {
    const url = '/merchant/class/editClass'
    return $axios.fPost(url, data)
}

// 获取待分班学员列表
export function listStudentForClass(data) {
    const url = '/merchant/class/listStudentForClass'
    return $axios.fPost(url, data)
}
// 根据课程获取学员列表
export function listStudentByCourse(data) {
    const url = '/merchant/course/user/listStudentForCourse'
    return $axios.fPost(url, data)
}

// 增加班级学员
export function addStudent(data) {
    const url = '/merchant/class/addStudent'
    return $axios.fPost(url, data)
}

// 删除班级学员
export function delStudent(data) {
    const url = '/merchant/class/delStudent'
    return $axios.fPost(url, data)
}

// 调班
export function changeClass(data) {
    const url = '/merchant/class/changeClass'
    return $axios.fPost(url, data)
}

// 批量排课
export function addBat(data) {
    const url = '/merchant/lesson/addBat'
    return $axios.fPost(url, data)
}

// 修改学员密码
export function changeStuPass(data) {
    const url = '/merchant/user/editStudent'
    return $axios.fPost(url, data)
}



// 获取关联的课节列表
export function listCourseUnit(data) {
    const url = '/merchant/lesson/listCourseUnit'
    return $axios.fPost(url, data)
}

// 获取班级学员信息
export function getClassStudentInfoById(data) {
    const url = '/merchant/class/getClassStudentInfoById'
    return $axios.fGet(url, data)
}

// 获取班级排课信息
export function getLessonInfoByClassId(data) {
    const url = '/merchant/classlession/getLessonInfoByClassId'
    return $axios.fGet(url, data)
}

// 获取班级学员动态
export function delLesson(data) {
    const url = '/merchant/lesson/del'
    return $axios.fPost(url, data)
}

// 编辑排课
export function lessonEdit(data) {
    const url = '/merchant/lesson/edit'
    return $axios.fPost(url, data)
}

// 获取员工列表
export function getListEmployee(data) {
    const url = '/merchant/user/listAllEmployee'
    return $axios.fGet(url, data)
}

// 批量修改排课时间
export function editBatTime(data) {
  const url = '/merchant/lesson/editBat'
  return $axios.fPost(url, data)
}

// 课程下任务以及编程任务下题目（已开放的学员任务）
export function getTaskByCourse (data) {
  const url = '/merchant/task/getTaskByCourse'
  return $axios.fPost(url, data)
}

// 批量开放补课视频
export function insertAllWithStatus (data) {
  const url = '/merchant/courseUnit/insertAllWithStatus'
  return $axios.fPost(url, data)
}

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("el-card", { staticStyle: { "margin-bottom": "10px" } }, [
        _c("div", [
          _vm._v(" 班级名称： "),
          _vm.classInfo
            ? _c("span", {
                domProps: { textContent: _vm._s(_vm.classInfo.className) },
              })
            : _vm._e(),
        ]),
        _c("div", { staticStyle: { margin: "10px 0" } }, [
          _vm._v(" 任课老师： "),
          _vm.classInfo
            ? _c("span", {
                domProps: { textContent: _vm._s(_vm.classInfo.zjName) },
              })
            : _vm._e(),
        ]),
        _c("div", { staticStyle: { margin: "10px 0" } }, [
          _vm._v(" 班级类型： "),
          _c("span", [_c("span", [_vm._v(_vm._s(this.classInfo.type))])]),
        ]),
        _c("div", { staticStyle: { margin: "10px 0" } }, [
          _vm._v(" 班容类型： "),
          _c("span", [_c("span", [_vm._v(_vm._s(this.classInfo.lctype))])]),
        ]),
        _c("div", { staticStyle: { margin: "10px 0" } }, [
          _vm._v(" 授课课程： "),
          _vm.classInfo && this.classInfo.course != null
            ? _c("span", [
                _c("span", [_vm._v(_vm._s(this.classInfo.course.title))]),
              ])
            : _vm._e(),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }